.bars-container {
  height: 1rem;
  width: 1.5rem;
  transform: scaleX(4);
  color: #0177fb;
  overflow: hidden;
  position: absolute;
  left: -1rem;
  top: -.5rem;
}

.bar {
  position: absolute;
  bottom: 0px;
  height: 1px;
  width: 1px;
  background: #0177fb;
  animation: sound 0ms -800ms linear infinite alternate;
}

@keyframes sound {
  0% {
    opacity: 0.35;
    height: 3px;
  }
  100% {
    opacity: 1;
    height: 28px;
  }
}

.bar.hello {
  animation: none;
}
.bars-container .bar {
  animation-duration: 1000ms;
}
