.audioContainer {
  border-radius: 9px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 19px;
  padding: 10px 19px;
}
.recording-circle {
    background-color: red;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    animation: ease pulse 2s infinite;
    margin-right: 0.25em;
    margin-right: 10px;
  }
 
  @keyframes pulse {
    0% {
      background-color: red;
    }
    50% {
      background-color: #914d4d;
    }
    100% {
      background-color: red;
    }
  }