.bvit-container {
    background-color: #F5F6FA;
    margin-top: 20px;
    padding: 10px 20px;
}

.bvit-header {
    margin: 0;
    font-size: 30px;
    color: #2D3394;
    line-height: 54px;
    font-weight: 500;
}