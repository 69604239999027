.main-tab {
    border: none;
    background-color: transparent;
    line-height: 27px;
    border-right: 1px solid #2D3394;
}

.tab-active {
    color: #2D3394;;
    border-bottom: 2px solid #2D3394;
}

.tabs-content {
    margin-top: 10px;
}