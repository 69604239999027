.bvit-data-table {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-top: 10px;
  font-size: 13px;
  text-align: left;
  border-collapse: collapse;
}

.bvit-data-table tr:nth-child(even) {
  background-color: #F5F6FA
}

.bvit-data-table tr th {
  color: #2D3394;
  border-bottom: 1px solid #E5E5ED;
  padding: 10px 7px 10px 40px;
}

.bvit-data-table tr td {
  color: #81818F;
  padding: 10px 15px 10px 40px;
  border-bottom: 1px solid #E5E5ED;
  width: 10px;
}

.download-img {
  margin-right: 5px;
}

.download-btn {
  border: none;
  background-color: #2D3394;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  padding: 8px;
}