/* width */
.businessSolutionHolderStyle::-webkit-scrollbar {
    height: 5px;
  }
  
  /* Track */
  .businessSolutionHolderStyle::-webkit-scrollbar-track {
    background: #d3d3d3;
    border-radius: 30px;
  }
  
  /* Handle */
  .businessSolutionHolderStyle::-webkit-scrollbar-thumb {
    background: #a9a9a9;
    border-radius: 30px;
  }
  
  /* Handle on hover */
  .businessSolutionHolderStyle::-webkit-scrollbar-thumb:hover {
    background: #555;
  }