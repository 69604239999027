.file-uploaded {
    display: grid;
    grid-template-columns: 5fr 1fr 1fr;
    background-color: #E5E5F2;
    color: #2D3394;
    padding: 15px 10px;
    border-radius: 10px;
}

.file-container {
    align-items: center;
    display: flex;
}

.file-details {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
}

.file-name {
    font-size: 15px;
}

.file-size {
    font-size: 10px;
    color: #8587AF;
}

.proceed-btn, .delete-btn {
    border-style: none;
    background-color: #fff;
    border-radius: 5px;
}

.proceed-btn {
    color: #3CB229;
    margin-right: 10px;
}

.delete-btn {
    color: #DD3737;
    grid-column-start: 3;
    grid-column-end: 4; 
}

.upload-img{
    margin-right: 10px;
}

.bvit-data-select {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    margin-top: 10px;
}

.type-select {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    height: fit-content;
    padding: 5px 0 7px;
}

.generate-img {
    vertical-align: bottom;
    margin-right: 10px;
}

.reset-filters {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.reset-btn, .generate-btn {
    border-style: none;
    background-color: #2D3394;
    color: #fff;
    border-radius: 5px;
    width: 171px;
    padding: 8px 30px;
}

.reset-btn {
    margin-right: 15px;
}

.generate-btn {
    margin: 10px 15px 15px;
}