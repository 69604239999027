.tableHeaderStyle{
  background-color: #0177fb;
  width: fit-content;
}
.tableRow{
    padding:0px 5px;
    width: fit-content;
    border:1px solid #bcbcbc;
}
.mcHeaderCellStyles {
  display: inline-block;
  color: #ffffff;
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  min-width: 180px;
  max-width: 180px;
}
.headerCombStringStyle{
    min-width: 200px;
}
.headerPositionStyle{
    min-width: 200px;
}
.headerCarsModelStyle{
    min-width: 170px;
    max-width: 170px;
}
.headerLoadFactorStyle{
    min-width: 170px;
    max-width: 170px;
}
.groupCell{
  min-width: 180px;
  max-width:180px;
}
.loadFactorCell{
  min-width:160px !important;
  max-width:160px !important;
}
.carsModelCustomCellStyle{
  display: inline-block;
  color: #000000;
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  white-space: wrap;
}
.groupCustomCellStyle{
  display: inline-block;
  color: #000000;
  padding: 5px;
  font-weight: 400;
  text-align: center;
  white-space: wrap;
}
.mcRowCellStyles {
  /* flex: 1; */
  display: inline-block;
  color: #000000;
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 180px;
  max-width: 180px;
  vertical-align: middle;
}
.deckLblStyle{
    display:inline-block;
    font-size:10px;
    vertical-align: middle;
    padding:0px 5px;
}
.upperDeckStyle{
    margin-bottom:2px;
}
.deckStyle {
  display:inline-block;
  vertical-align: middle;
}
.deckItemStyle {
    display: inline-block;
    padding:5px;
    height: 16px;
    font-size: 10px;
    color: #4d4f52 !important;
    font-weight: bold;
    line-height: 0.5rem;
}
.carModelsStyle{
  display: inline-block;
  padding: 2px 5px;
  border-radius: 4px;
  background-color: #dfdfdf;
  font-size: 10px;
  color: #726363;
  margin-right: 3px;
}
.combString{
    min-width:200px;
}
.carsModelCell{
  white-space: wrap;
}
.positionCell{
    min-width: 200px;
}
.combStringStyle{
    display:inline-block;
    font-size:10px;
    padding:2px 5px;
    color:#4d4f52;
    font-weight: bold;
}
.backgroundAColor {
  background-color: #f7d8ba;
}
.backgroundBColor {
  background-color: #adf7b6;
}
.backgroundCColor {
  background-color: #fcf5c7;
}
.backgroundDColor {
  background-color: #7cdddd;
}
.backgroundEColor {
  background-color: #ffee93;
}
.backgroundFColor {
  background-color: #acddde;
}
.backgroundGColor {
  background-color: #a5abb5;
}
.backgroundHColor {
  background-color: #ffc09f;
}
.backgroundIColor {
  background-color: #eff9da;
}
.backgroundJColor {
  /* background-color: #fff1c9; */
  background-color: #ffd6df;
}
.modelCarrierTableStyle{
  white-space: nowrap;
  overflow-x: auto;
  border-radius: 5px;
  border:1px solid grey;
}

/* width */
.modelCarrierTableStyle::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.modelCarrierTableStyle::-webkit-scrollbar-track {
  background: #d3d3d3;
  border-radius: 30px;
}

/* Handle */
.modelCarrierTableStyle::-webkit-scrollbar-thumb {
  background: #a9a9a9;
  border-radius: 30px;
}

/* Handle on hover */
.modelCarrierTableStyle::-webkit-scrollbar-thumb:hover {
  background: #555;
}