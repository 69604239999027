.sobTableStyle{
    white-space: wrap;
    overflow-x: auto;
    border-radius: 5px;
    border:1px solid grey;
}

/* width */
.sobTableStyle::-webkit-scrollbar {
    height: 5px;
  }
  
  /* Track */
  .sobTableStyle::-webkit-scrollbar-track {
    background: #d3d3d3;
    border-radius: 30px;
  }
  
  /* Handle */
  .sobTableStyle::-webkit-scrollbar-thumb {
    background: #a9a9a9;
    border-radius: 30px;
  }
  
  /* Handle on hover */
  .sobTableStyle::-webkit-scrollbar-thumb:hover {
    background: #555;
  }