
/* width */
.homePageCardsStyle::-webkit-scrollbar {
    height: 3px;
  }
  
  /* Track */
  .homePageCardsStyle::-webkit-scrollbar-track {
    background: #d3d3d3;
    border-radius: 30px;
  }
  
  /* Handle */
  .homePageCardsStyle::-webkit-scrollbar-thumb {
    background: #a9a9a9;
    border-radius: 30px;
  }
  
  /* Handle on hover */
  .homePageCardsStyle::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .textAreaStyles{
    height:80px !important;
    border-color: #cccccc;
    border-radius: 5px;
    font-family:sans-serif;
    font-size:14px;
    padding:10px;
}

.textAreaStyles:focus-visible{
    border:2px solid #0177FB;
    outline: none;
}