body {
  margin: 0;
  font-family: BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
}

code {
  font-family: 'source-code-pro', 'Menlo', 'Monaco', 'Consolas', 'Courier New',
    monospace;
}
