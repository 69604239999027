.tab-model {
   background-color: #fff;
   border-radius: 10px;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   padding: 5px 0 15px;
   margin-right: 10px;
}

.select-box {
   color: #2D3394;
   border: 1px solid #2D3394;
   /* border-radius: 5px;
   margin: 10px 0 0 10px;
   width: 177px;
   height: 30px; */
}