.graphs-heading {
    color: #2D3394;
    font-size: 16px;
    margin: 10px 0 5px;
}

.bvit-graphs {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    padding: 10px;
    font-size: 14px;
    color: #2D3394;
}

.bvit-charts {
    height: 600px;
    width: 950px;
    overflow-y: scroll;
    margin: 10px 0;
}

.bvit-chart {
    /* border: 2px solid #000; */
    border-radius: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
}

.chart-option {
    padding: 0 0 20px 10px;
    border-top: 1px solid #ECECEC;
}

.graph-buttons {
    display: flex;
    justify-content: end;
}

.back-btn, .save-btn, .email-btn {
    border-style: none;
    background-color: #2D3394;
    color: #fff;
    border-radius: 5px;
    padding: 10px;
}

.back-btn {
    width: 80px;
}

.save-btn, .email-btn {
    width: 120px;
    margin-left: 10px;
}

.disabled {
    cursor: not-allowed;
    background-color: #8587AF;
}

.graph-btns {
    margin-right: 10px;
}

.input-fields {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.graph-nodata {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #8587AF;
    border: 1px solid #E5E5ED;
    border-radius: 10px;
    padding: 40px 0;
    margin: 10px 0;
}

.graph-error {
    font-size: 26px;
}

.error-msg {
    color: #2D3394;
    font-weight: 700;
}

.graph-suberror {
    font-size: 14px;
}