.customSelectStyle{
    padding:10px;
    width:150px;
}
.customSelectStyle .MuiSelect-select{
    padding:0px;
}
.customSelectStyle .MuiInputBase-input{
    font-size:14px;
}
.customDatePickerStyle{
    width: 150px;
}
.customDatePickerStyle .MuiInputBase-root{
    font-size:14px;
}
.customDatePickerStyle .MuiInputBase-input{
    padding:10px;
}
.headerCellStyles{
    display:inline-block;
    color: #ffffff;
    padding: 5px;
    font-size: 13px;
    font-weight: 400;
    min-width:160px;
    max-width: 160px;
}
.rowCellStyles{
    display:inline-block;
    color:#000000;
    padding: 5px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 160px;
    max-width:160px;
    vertical-align: middle;
}
.subTableStyles{
    display: inline-block;
    padding: 0px;
    border-radius: 4px;
    background-color: #edeef9;
    margin: 5px;
    vertical-align: middle;
}
.subTableStyles:nth-child(even){
    background-color: #ff0000;
}
.rowInnerCellStyles{
    display:inline-block;
    color:#000000;
    padding: 5px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 160px;
    max-width: 160px;
}
.areaOfOperationCell{
    min-width: 300px;
    max-width: 300px
}

.customRadioLblStyle .MuiFormControlLabel-label{
    font-size: 14px;
}

/* pie charts css  */

.pieChartHolder,
.highcharts-figure,
.highcharts-data-table table {
    min-width: 450px;
}
.pieChartHolder .highcharts-container  {
    max-height: 300px;
}
.pieChartHolder .highcharts-container .highcharts-root{
    max-height: 300px;
}
.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}
.dateFieldStyle{
    border:1px solid rgb(155, 153, 153);
    padding:7px 15px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    cursor: text;
}
