.tab-summary {
   background-color: #fff;
   border-radius: 10px;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   margin-top: 10px;
   padding: 10px;
   font-size: 14px;
}

.graph-summary {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
   padding: 5px 10px;
}

.summary-mainText {
   font-size: 12px;
   color: #797979;
}

.summary-subText {
   font-size: 14px;
   color: #2D3394;
   font-weight: 500;
   padding-right: 5px;
}